import {GeneralConfig} from "../../_core/types/theme/General.type";

const company = {
    name: 'Imfiy BV',
    kvk: '67058094',
    address: 'Raamplein 1, 1016XK Amsterdam',
    phone: '0617422916',
};

const general: GeneralConfig =  {
    siteName: 'Seeer',
    domain: 'seeer.com',
    logoDark: '/static/images/theme_icons/seeer/logo-dark.svg',
    company,
    address: `${company.name}<br>KVK-nummer: ${company.kvk}<br>${company.address}<br><br>Call: <a href="tel:${company.phone}" class="!text-primary-100">${company.phone}</a><br>Mon - Fri 9:00 - 17:00`,
    advisors: 'advisors',
    advisor: 'advisor',
    supportEmail: 'support@seeer.com',
    socials: {
        facebook: 'https://www.facebook.com/seeercom/',
        instagram: 'https://www.instagram.com/seeercom/',
        tiktok: 'https://www.tiktok.com/@seeercom',
        youtube: 'https://www.youtube.com/@seeer6576',
    }
};

// @ts-ignore
export default general;
